import React from 'react';

import {
  PaymentMethods,
  BannerWarning,
  PrimaryButtonLarge,
  LocaleSelection,
  HomeCustomisation,
} from '@fingermarkglobal/cringer.components';

import Logo from '../../../assets/images/logo.svg';
import WashHandsImage from '../../../assets/images/wash-hands.png';
import DefaultHeroImage from '../../../assets/images/burger.png';
import DefaultBackgroundImage from '../../../assets/images/BG.png';

const SecondaryHome = ({
  t = () => {},
  card,
  cash,
  icons,
  languages,
  healthMsg = true,
  className = '',
  primaryAction,
  secondaryAction,
  onChangeLanguage,
  accessibilityEnabled = false,
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
}) => {
  const isBordered = !!(icons?.length || card);

  return (
    <HomeCustomisation.Provider
      fallback={{
        background: {
          image: DefaultBackgroundImage,
        },
        hero: {
          image: DefaultHeroImage,
        },
      }}
    >
      <HomeCustomisation.Background />

      <div
        data-test="secondary-home"
        className={`z-10 flex flex-col justify-between items-center h-full w-full overflow-y-hidden ${className} ${
          accessibilityEnabled ? 'mt-40' : ''
        }`}
      >
        <div
          data-test="home-upper"
          className="flex flex-col items-center justify-start w-full h-full"
        >
          <PaymentMethods
            t={t}
            card={card}
            cash={cash}
            Logo={Logo}
            icons={icons}
            enableLogo={!accessibilityEnabled}
            className={`px-12 py-4 text-7xl ${isBordered ? 'border-2 border-black' : ''}  rounded ${
              isBordered && !accessibilityEnabled ? 'mt-2' : ''
            }`}
          />

          {!accessibilityEnabled && <HomeCustomisation.HeroImage />}
        </div>

        <div
          data-test="home-lower"
          className="flex flex-col-reverse items-center justify-start w-full h-full"
        >
          {showTermsAndConditionsButton && (
            <BannerWarning
              t={t}
              width="109"
              className="w-4/5 mb-2"
              title={t('homeTermsAndConditionsBannerWarningTitle')}
              description={t('homeTermsAndConditionsBannerWarningDescription')}
              additionalDescription={t('homeTermsAndConditionsBannerWarningAdditionalDescription')}
              additionalDescriptionAction={() => setShowTermsAndConditionsModal(true)}
            />
          )}

          {healthMsg && (
            <BannerWarning
              t={t}
              width="109"
              className="w-4/5 mb-2"
              data-test="banner-health"
              image={WashHandsImage}
            />
          )}

          <div
            data-test="home-services"
            className={`flex items-stretch justify-center w-4/5 ${
              healthMsg ? 'mb-16' : 'mb-72'
            } space-x-16`}
          >
            {!!secondaryAction && (
              <PrimaryButtonLarge
                className="w-1/2 bg-white rounded-3xl"
                data-test="button-secondary"
                onClick={secondaryAction}
              >
                {t('homeSecondaryAction')}
              </PrimaryButtonLarge>
            )}

            {!!primaryAction && (
              <PrimaryButtonLarge
                className="w-1/2 bg-white rounded-3xl"
                data-test="button-primary"
                onClick={primaryAction}
              >
                {t('homePrimaryAction')}
              </PrimaryButtonLarge>
            )}
          </div>
        </div>

        <div className="flex justify-center w-full mt-8" data-test="home-footer">
          <LocaleSelection languages={languages} onChangeLanguage={onChangeLanguage} />
        </div>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { SecondaryHome };
