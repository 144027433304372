import React from 'react';
import {
  ReceiptDetails,
  ReceiptPayment,
  CartViewProduct,
  useReceiptView,
} from '@fingermarkglobal/cringer.components';
import Leaf from '../../assets/images/leaf.svg';

const CustomReceiptView = () => {
  const {
    cart,
    receiptPaymentData,
    receiptDetailsData,
    t,
    formatPrice,
    showAddonAvailability,
  } = useReceiptView();

  return (
    <div data-test="receipt-page" className="flex flex-col items-center w-full h-full space-y-8">
      <div className="flex items-center justify-start w-full h-32 px-8 py-6 mt-6 bg-accessory">
        <div className="text-5xl font-bold uppercase">{t('receiptTitle')}</div>
        <Leaf className="w-12 ml-8 mr-4" />
        <div className="text-3xl font-bold text-leaf-green">{t('receiptEcoMessage')}</div>
      </div>
      <div className="flex flex-col items-center w-full h-full p-8 space-y-8 overflow-y-auto">
        <ReceiptDetails receiptDetailsData={receiptDetailsData} t={t} boxClassName="shadow-lg" />
        {cart &&
          cart.map(product => (
            <CartViewProduct
              product={product}
              key={product.uid}
              t={t}
              boxClassName="shadow-lg"
              formatPrice={formatPrice}
              showAddonAvailability={showAddonAvailability}
            />
          ))}
      </div>
      <div className="flex flex-col items-center justify-center w-full p-8 space-y-8">
        <ReceiptPayment
          receiptPaymentData={receiptPaymentData}
          t={t}
          boxClassName="shadow-lg"
          showTaxNumber={false}
          showTaxTotal={true}
        />
      </div>
    </div>
  );
};

export { CustomReceiptView };
