import React from 'react';

import { useLocation } from 'react-router-dom';

import {
  AccessibilityWrapper,
  AccessibilityButton,
  TimeoutModal,
  useTemplate,
  Footer,
} from '@fingermarkglobal/cringer.components';

import { Decoration } from '../../components/stateless/decoration';
import { Header } from '../../components/stateless/header';

import CartIconImage from '../../assets/images/cart-icon.svg';
import Wheelchair from '../../assets/images/wheelchair.svg';

const CustomDefaultTemplate = ({
  children,
  footerEnabled = true,
  headerEnabled = true,
  timeoutEnabled = true,
  templateOverride = false,
  accessibilityActionEnabled = true,
}) => {
  const {
    header,
    footer,
    modalAccessibilityClass,
    accessibilityEnabled,
    isTimeoutEnabled,
    timeoutActionOverride,
    isOnline,
    isCouponEnabled,
  } = useTemplate({
    timeoutEnabled,
    templateOverride,
  });

  const location = useLocation();

  const showFooterAccessibilityButton = accessibilityActionEnabled && footerEnabled;
  const showStaticAccessibilityButton = accessibilityActionEnabled;
  const isHome = location.pathname === '/home';

  const isAccessibilityButtonEnabled =
    accessibilityActionEnabled || showFooterAccessibilityButton || showStaticAccessibilityButton;

  return (
    <div className="flex flex-col justify-between h-screen">
      <Decoration showLogo={accessibilityEnabled && isAccessibilityButtonEnabled} />
      <AccessibilityWrapper
        className={`flex flex-col ${!accessibilityEnabled && 'accessibility-static'}`}
        isAccessibilityButtonEnabled={isAccessibilityButtonEnabled}
      >
        <div className="flex flex-col w-screen h-full">
          {headerEnabled && <Header {...header} isCouponEnabled={isCouponEnabled} />}

          <div className="flex flex-col h-full overflow-auto">{children}</div>

          {footerEnabled && (
            <Footer
              {...footer}
              isDefaultButtonPaddingEnabled={false}
              OverwriteCartIcon={CartIconImage}
              accessibilityEnabled={accessibilityEnabled}
              showAccessibilityButton={showFooterAccessibilityButton}
            />
          )}

          {showStaticAccessibilityButton && (
            <AccessibilityButton
              AccessibilityIcon={Wheelchair}
              className={`absolute bottom-0 left-0 z-40 block w-24 h-24 p-2 my-8 ml-8 shadow-lg ${isHome &&
                'text-white'}`}
            />
          )}

          {isTimeoutEnabled && isOnline && (
            <TimeoutModal
              onTimeout={timeoutActionOverride}
              modalAccessibilityClass={modalAccessibilityClass}
            />
          )}
        </div>
      </AccessibilityWrapper>
    </div>
  );
};

export { CustomDefaultTemplate };
